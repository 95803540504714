<template>
    <div class="typography">
        <div class="typography-top flex">
            <div class="top-name">{{ $t('brand.desc35') }}</div>
            <div class="top-info">
                <div class="info flex">
                    <div>{{ $t('brand.desc37') }}</div>
                    <p>aaBbCcDdEe/1234567890</p>
                </div>
                <div class="info flex">
                    <div>{{ $t('brand.desc38') }}</div>
                    <p>aaBbCcDdEe/1234567890</p>
                </div>
                <div class="info flex">
                    <div>{{ $t('brand.desc39') }}</div>
                    <p>aaBbCcDdEe/1234567890</p>
                </div>
            </div>
        </div>
        <div class="planetd flex">
            <div class="planetd-left">{{ $t('brand.desc36') }}</div>
            <div class="planetd-more flex">
                <div>{{ $t('brand.desc36') }}</div>
                <div>{{ $t('brand.desc36') }}</div>
                <div>{{ $t('brand.desc36') }}</div>
                <div>{{ $t('brand.desc36') }}</div>
                <div>{{ $t('brand.desc36') }}</div>
            </div>
        </div>
        <div class="typographyimg">
            <img src="../../public/images/brand/typographyimg.svg" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="less" scoped>
*{
    font-family: 'Montserrat';
}
.typography{
    padding-top: 153px;
    .typography-top{
        height: 270px;
        border-bottom: 1px solid rgba(255, 255, 255, .3);
        .top-name{
            padding-left: 100px;
            flex: 0 0 50%;
            font-size: 60px;
            color: #fff;
            font-weight: 550;
            text-transform: capitalize;
        }
        .top-info{
            flex: 0 0 50%;
            .info{
                margin-bottom: 40px;
                justify-content: space-between;
                font-size: 26px;
                line-height: 36px;
                color: rgba(255, 255, 255, .4);
                font-weight: 300;
                text-transform: capitalize;
                &:last-child{
                    margin-bottom: 0;
                    color: #fff;
                    font-weight: 550;
                }
                &:nth-child(2){
                    color: rgba(255, 255, 255, .7);
                    font-weight: 400;
                }
            }
        }
    }
    .planetd{
        padding: 80px 0 0 100px;
        .planetd-left{
            flex: 0 0 50%;
            font-size: 66px;
            color: #fff;
            line-height: 92px;
            text-transform: uppercase;
            font-family: 'Planeto';
        }
        .planetd-more{
            flex:0 0 50%;
            flex-wrap: wrap;
            div{
                flex: 0 0 33.3%;
                margin-top: 18px;
                font-size: 22px;
                line-height: 30px;
                text-transform: uppercase;
                color: rgba(255, 255, 255, .2);
                font-family: 'Planeto';
                &:nth-child(-n + 2){
                    margin-top: 0;
                }
                &:nth-child(2){
                    flex: 0 0 66.6%;
                    color: rgba(255, 255, 255, .4);
                }
                &:nth-child(3){
                    color: rgba(255, 255, 255, .6);
                }
                &:nth-child(4){
                    color: rgba(255, 255, 255, .6);
                }
                &:nth-child(5){
                    color: #fff;
                }
            } 
        }
    }
    .typographyimg{
        display: none;
    }
}
@media (max-width:1200px) {
    .typography{
        padding: 164px 0 80px;
        .typography-top,.planetd{
            display: none;
        }
        .typographyimg{
            display: block;
            img{
                width: 100%;
            }
        }
    }
}
</style>