<template>
    <div class="layout">
        <div class="layout-name">{{ $t('brand.desc19') }}</div>
        <div class="layout-con">
            <img src="../../public/images/brand/layoutimg.png" alt="" class="img1" />
            <img src="../../public/images/brand/samlllayoutimg.png" alt="" class="img2" />
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="less" scoped>
*{
    font-family: 'Montserrat';
}
.layout{
    padding: 132px 0 0 100px;
    .layout-name{
        margin-bottom: 60px;
        font-size: 16px;
        color: #fff;
        text-transform: capitalize;
        font-weight: 300;
        line-height: 22px;
    }
    .layout-con{
        img{
            width: 100%;
        }
        .img2{
            display: none;
        }
    }
}
@media (max-width:1200px) {
    .layout{
        padding: 164px 0 80px;
        .layout-name{
            margin-bottom: 34px;
            font-size: 14px;
            line-height: 18px;
        }
        .layout-con{
            .img1{
                display: none;
            }
            .img2{
                display: block;
            }
        }
    }

} 
</style>