<template>
    <div class="safe">
        <div class="safe-rules">
            <div>{{ $t('brand.desc25') }}</div>
            <ul>
                <li>{{ $t('brand.desc26') }}</li>
                <li>{{ $t('brand.desc27') }} <span>X</span></li>
                <li>{{ $t('brand.desc28') }}</li>
            </ul>
        </div>
        <div class="safe-img">
            <img src="../../public/images/brand/safeimg.png" alt="" />
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="less" scoped>
*{
    font-family: 'Montserrat';
}
.safe{
    padding-top: 113px;
    .safe-rules{
        padding-left: 100px;
       height: 199px;
        border-bottom: 1px solid rgba(255, 255, 255, .3);
        text-transform: capitalize;
        div{
            margin-bottom: 38px;
            font-size: 16px;
            color: #fff;
            line-height: 22px;
        }
        li{
            font-size: 14px;
            color: rgba(255, 255, 255, .7);
            line-height: 20px;
            span{
                color: rgba(253, 181, 0, .7);
            }
        }
    }
    .safe-img{
        padding: 60px 0 0 100px;
        img{
            width: 100%;
        }
    }
}
@media (max-width:1200px) {
    .safe{
        padding: 164px 0 80px;
        .safe-rules{
            padding-left: 0;
            height: fit-content;
            border-bottom: 0;
            padding-bottom: 44px;
            div{
                margin-bottom: 13px;
                font-size: 14px;
                line-height: 18px;
            }
            li{
                font-size: 14px;
                line-height: 18px;
            }
        }
        .safe-img{
            padding: 0;
        }
    }
}
</style>