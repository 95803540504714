<template>
    <div class="color flex">
        <div class="color-black flex">
            <div class="black-name">{{ $t('brand.desc29') }}</div>
            <div class="black-right">
                <div>{{ $t('brand.desc30') }}</div>
                <img src="../../public/images/brand/icon1.png" alt="" />
                <ul>
                    <li>CMYK</li>
                    <li>93 88 89 80</li>
                    <li>RGB</li>
                    <li>0 0 0</li>
                </ul>
            </div>
        </div>
        <div class="color-white">
            <div class="white-top">
                <div>{{ $t('brand.desc31') }}</div>
                <img src="../../public/images/brand/icon2.png" alt="" />
                <ul>
                    <li>CMYK</li>
                    <li>0 0 0 0</li>
                    <li>RGB</li>
                    <li>255 255 255</li>
                </ul>
            </div>
            <div class="white-more flex">
                <div class="more-list">
                    <div>{{ $t('brand.desc32') }}</div>
                    <img src="../../public/images/brand/icon2.png" alt="" />
                    <ul>
                        <li>CMYK</li>
                        <li>0 39 100 0</li>
                        <li>RGB</li>
                        <li>253 181 0</li>
                    </ul>
                </div>
                <div class="more-gery">
                    <div class="grey-info">
                        <div>{{ $t('brand.desc33') }}</div>

                        <ul>
                            <li>CMYK/83 78 77 60 </li>
                            <li>RGB/34 34 34</li>
                        </ul>
                    </div>
                    <div class="grey-info">
                        <div>{{ $t('brand.desc34') }}</div>

                        <ul>
                            <li>CMYK/0 80 94 0</li>
                            <li>RGB/253 84 0</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="color-img">
            <img src="../../public/images/brand/colorimg.svg" alt="" />
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
* {
    font-family: 'Montserrat';
}

.color {
    padding: 170px 0 0 100px;

    .color-black {
        flex: 0 0 50%;
        height: 500px;
        background: url('../../public/images/brand/colorbg.png') center no-repeat;
        background-size: 100% 100%;

        .black-name {
            flex: 1;
            padding: 40px 0 0 30px;
            font-size: 14px;
            color: rgba(255, 255, 255, .7);
            text-transform: capitalize;
        }

        .black-right {
            padding: 40px 28px 0 0;
            text-align: right;

            div {
                font-size: 18px;
                color: #fff;
                line-height: 25px;
            }

            img {
                width: 8px;
                height: 8px;
                margin: 10px 0;
            }

            li {
                font-size: 14px;
                line-height: 19px;
                color: rgba(255, 255, 255, .7);
            }
        }
    }

    .color-white {
        flex: 0 0 50%;

        .white-top {
            height: 250px;
            background: url('../../public/images/brand/colorbg1.png') center no-repeat;
            background-size: 100% 100%;

            div {
                padding: 86px 0 0 30px;
                font-size: 18px;
                color: #000000;
                line-height: 25px;
                font-weight: 400;
                text-transform: capitalize;
            }

            img {
                width: 8px;
                height: 8px;
                margin: 10px 0 10px 30px;
            }

            li {
                font-size: 14px;
                line-height: 19px;
                padding-left: 30px;
                color: rgba(0, 0, 0, .7);
            }
        }

        .white-more {
            .more-list {
                flex: 0 0 50%;
                height: 250px;
                text-transform: capitalize;

                &:first-child {
                    background: url('../../public/images/brand/colorbg2.png') center no-repeat;
                    background-size: 100% 100%;
                }

                div {
                    padding: 86px 0 0 30px;
                    font-size: 18px;
                    color: #000;
                    line-height: 25px;
                }

                img {
                    width: 8px;
                    height: 8px;
                    margin: 10px 0 10px 30px;
                }

                li {
                    font-size: 14px;
                    padding-left: 30px;
                    line-height: 19px;
                    color: rgba(0, 0, 0, .7);
                }
            }

            .more-gery {
                flex: 0 0 50%;

                .grey-info {
                    padding: 30px 0 0 20px;
                    height: 125px;
                    background: url('../../public/images/brand/colorbg3.png') center no-repeat;
                    background-size: 100% 100%;
                    font-size: 14px;
                    color: rgba(255, 255, 255, .7);
                    line-height: 19px;
                    text-transform: capitalize;

                    div {
                        font-size: 18px;
                        line-height: 25px;
                        margin-bottom: 10px;
                        color: #fff;
                    }

                    &:last-child {
                        background: url('../../public/images/brand/colorbg4.png') center no-repeat;
                        background-size: 100% 100%;
                        color: rgba(6, 6, 6, .7);

                        div {
                            color: #060606;
                        }
                    }

                }
            }
        }
    }
    .color-img{
        display: none;
    }
}
@media (max-width:1200px) {
    .color{
        padding: 164px 0 0;
        .color-black{
           display: none;
        }
        .color-white{
           display: none;
        }
        .color-img{
            display: block;
            width: 100%;
            img{
                width: 100%;
            }
        }
    }
}
</style>