<template>
    <div class="background">
        <div class="background-title">{{ $t('brand.desc20') }}</div>
        <div class="background-list flex">
            <div class="list-info" v-for="(item, index) in 10" :key="index">
                <p>{{ (index + 1) * 10 }}%</p>
                <div class="flexcenter">
                    <img src="../../public/images/brand/logo.png" alt="" class="img1" />
                    <img src="../../public/images/brand/error.png" alt="" class="img2" v-if="index < 6" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
*{
    font-family: 'Montserrat';
}
.background {
    padding-top: 113px;

    .background-title {
        padding: 0 0 0 100px;
        height: 87px;
        font-size: 16px;
        color: #fff;
        line-height: 22px;
        border-bottom: 1px solid rgba(255, 255, 255, .3);
        text-transform: capitalize;
    }

    .background-list {
        padding-left: 100px;
        flex-wrap: wrap;
        justify-content: space-between;
        .list-info {
            flex: 0 0 178px;
            margin: 60px 27px 0 0;

            &:nth-child(5n) {
                margin-right: 0;
            }

            p {
                font-size: 14px;
                color: rgba(255, 255, 255, .7);
                line-height: 20px;
            }

            div {
                position: relative;
                width: 100%;
                height: 100px;
                background-color: #E1E1E1;
                background: url('../../public/images/brand/linebg.png') center no-repeat;
                background-size: 100% 100%;
                .img1{
                    width: 126px;
                    height: 20px;
                }
                .img2{
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    width: 20px;
                    height: 20px;
                }
            }
            &:nth-child(2){
                div{
                    background-color: #C7C7C7;
                }
            }
            &:nth-child(3){
                div{
                    background-color: #AEAEAE;
                }
            }
            &:nth-child(4){
                div{
                    background-color: #949494;
                }
            }
            &:nth-child(5){
                div{
                    background-color: #808080;
                }
            }
            &:nth-child(6){
                div{
                    background-color: #666666;
                }
            }
            &:nth-child(7){
                div{
                    background-color: #4D4D4D;
                }
            }
            &:nth-child(8){
                div{
                    background-color: #333333;
                }
            }
            &:nth-child(9){
                div{
                    background-color: #1A1A1A;
                }
            }
            &:nth-child(10){
                div{
                    background-color: #000000;
                }
            }
        }
    }

}

@media (max-width:1200px) {
    .background{
        padding: 164px 0 80px;
        .background-title{
            padding: 0 0 41px 0;
            font-size: 14px;
            height: auto;
            border-bottom: 0;
            line-height: 18px;
        }
        .background-list{
            padding-left: 0;
            justify-content: flex-start;
            .list-info{
                flex: 0 0 48%;
                width: 48%;
                margin: 29px 4% 0 0;
                &:nth-child(5n){
                    margin-right: 4%;
                }
                &:nth-child(2n){
                    margin-right: 0;
                }
                &:nth-child(-n + 2){
                    margin-top: 0;
                }
                p{
                    font-size: 12px;
                }
                div{
                    height: 50px;
                    .img1{
                        width: auto;
                        height: 10px;
                    }
                }
            }
        }
    }
}
</style>