<template>
    <div class="errordemo flex">
        <div class="demo-list" v-for="item in list" :key="item.name">
            <div class="demo-name flex">
                <img src="../../public/images/brand/error.png" alt="">
                <div>{{ item.name }}</div>
            </div>
            <div class="demo-img">
                <img :src="item.img" alt="" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            list: [
                {
                    name: this.$t('brand.desc21'),
                    img: require('../../public/images/brand/errimg1.png')
                },
                {
                    name: this.$t('brand.desc22'),
                    img: require('../../public/images/brand/errimg2.png')
                },
                {
                    name: this.$t('brand.desc23'),
                    img: require('../../public/images/brand/errimg3.png')
                },
                {
                    name: this.$t('brand.desc24'),
                    img: require('../../public/images/brand/errimg4.png')
                }
            ]
        }
    },
}
</script>

<style lang="less" scoped>
*{
    font-family: 'Montserrat';
}
.errordemo {
    width: 670px;
    margin: 132px auto 0;
    flex-wrap: wrap;
    .demo-list{
        margin-bottom: 60px;
        flex: 0 0 320px;
        width: 320px;
        margin-right: 30px;
        &:nth-child(2n){
            margin-right: 0;
        }
        .demo-name{
            img{
                flex: 0 0 20px;
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }
            font-size: 16px;
            color: #fff;
            line-height: 20px;
            text-transform: capitalize;
            div{
                flex: 1;
                min-height: 44px;
                overflow: hidden;
            }
        }
        .demo-img{
            img{
                width: 320px;
            }
        }
    }
}
@media (max-width:1200px) {
    .errordemo{
        margin: 0;
        width: 100%;
        padding: 164px 0 80px;
        .demo-list{
            flex: 0 0 100%;
            width: 100%;
            margin: 0 0 24px 0;
            .demo-name{
                width: 150px;
                margin: 0 auto 7px;
                font-size: 12px;
                line-height: 14px;
                img{
                    flex: 0 0 10px;
                    width: 10px;
                    height: 10px;
                    margin-right: 2px;
                    margin-top: 2px;
                }
                div{
                    min-height: 22px;
                }
            }
            .demo-img{
                width: 150px;
                margin: 0 auto;
                img{
                    width: 100%;
                }
            }
        }
    }
}
</style>