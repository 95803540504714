<template>
    <div class="brand">
        <el-container>
            <el-aside width="300px">
                <el-scrollbar>
                    <div class="brand-logo">
                        <img src="../../public/images/logo.png" alt="" @click="$router.push('/home')" />
                    </div>
                    <div class="brand-nav">
                        <div class="flex" v-for="(item, index) in list" :key="item.id" :class="{ tabact: act == index }"
                            @click="handlerTab(item.id, index)">
                            <p>{{ item.name }}</p>
                            <p></p>
                        </div>
                    </div>
                    <div class="brand-resource" @click="$router.push('/brand/resource')">{{ $t('brand.desc2') }}</div>
                </el-scrollbar>
            </el-aside>
            <el-container>
                <el-header>
                    <div class="topimg">
                        <img src="../../public/images/logo.png" alt="" @click="$router.push('/home')" />
                    </div>
                    <div class="brand-top flex">
                        <div class="topact" @click="$router.push('/brand/component')">{{ $t('brand.desc1') }}</div>
                        <div @click="$router.push('/brand/resource')">{{ $t('brand.desc2') }}</div>
                    </div>
                    <div class="brand-select">
                        <el-select v-model="activieName">
                            <el-option v-for="item in list" :key="item.id" :label="item.name" :value="item.id"  />
                        </el-select>
                    </div>
                </el-header>
                <el-main><el-scrollbar>
                        <div class="brand-con">
                            <component :is="activieName"></component>
                        </div>
                    </el-scrollbar>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
import Layout from './layout.vue';
import Background from './background.vue';
import Errordemo from './errordemo.vue';
import Safe from './safe.vue';
import Color from './color.vue';
import Typography from './typography.vue';
export default {
    components: {
        Layout,
        Background,
        Errordemo,
        Safe,
        Color,
        Typography
    },
    data() {
        return {
            activieName: 'Layout',
            act: 0,
            list: [
                {
                    id: 'Layout',
                    name: this.$t('brand.desc13')
                },
                {
                    id: 'Background',
                    name: this.$t('brand.desc14')
                },
                {
                    id: 'Errordemo',
                    name: this.$t('brand.desc15')
                },
                {
                    id: 'Safe',
                    name: this.$t('brand.desc16')
                },
                {
                    id: 'Color',
                    name: this.$t('brand.desc17')
                },
                {
                    id: 'Typography',
                    name: this.$t('brand.desc18')
                }
            ]
        }
    },
    methods: {
        handlerTab(name, i) {
            this.act = i;
            this.activieName = name;
        }
    }
}
</script>

<style lang="less" scoped>
div,
p,
span {
    font-family: 'Montserrat';
}

.brand {
    padding: 0 100px;
    width: 100%;
    min-height: 100vh;
    background: url('../../public/images/brand/bg1.png') right bottom no-repeat;
    background-size: 800px 702px;
    background-color: #000;
    vertical-align: middle;
    ::v-deep .el-aside {
        position: relative;
        padding-top: 35px;
        min-height: 100vh;
        border-right: 1px solid rgba(118, 118, 118, .4);
        box-sizing: border-box;
        overflow: initial;

        .el-scrollbar__wrap--hidden-default,
        .el-scrollbar {
            overflow: initial;
        }

        .brand-logo {
            width: 189px;

            img {
                width: 189px;
                height: 30px;
                cursor: pointer;
            }
        }

        .brand-nav {
            margin-top: 120px;

            div {
                position: relative;
                margin-bottom: 40px;
                font-size: 16px;
                color: #FFFFFF;
                text-transform: capitalize;
                cursor: pointer;
                line-height: 16px;

                &:last-child {
                    margin-top: 0;
                }

                p {
                    &:first-child {
                        padding-right: 20px;
                    }

                    &:last-child {
                        flex: 1;
                        margin-top: 17px;
                        height: 1px;
                        background: rgba(255, 255, 255, .3);
                        display: none;
                    }
                }

                &:hover {
                    font-size: 36px;
                    line-height: 36px;
                    color: #FDB500;

                    &::after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        right: -5px;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background: #fff;
                        transform: translateY(-50%);
                    }

                    p {
                        &:last-child {
                            display: block;
                        }
                    }
                }
            }

            .tabact {
                font-size: 36px;
                line-height: 36px;
                color: #FDB500;

                &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: -5px;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background: #fff;
                    transform: translateY(-50%);
                }

                p {
                    &:last-child {
                        display: block;
                    }
                }

            }
        }

        .brand-resource {
            position: relative;
            position: absolute;
            bottom: 80px;
            left: 0;
            font-size: 14px;
            color: #fff;
            line-height: 14px;
            padding-left: 88px;
            text-transform: uppercase;
            font-weight: 300;
            cursor: pointer;

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                width: 60px;
                height: 1px;
                background: #D9D9D9;
                transform: translateY(-50%);
            }

            &:hover {
                color: #FDB500;
            }
        }
    }
 
    .brand-top {
        padding-top: 35px;
        justify-content: flex-end;
        font-size: 18px;
        color: #fff;
        line-height: 30px;
        cursor: pointer;

        div {

            text-transform: capitalize;

            &:last-child {
                margin-left: 70px;
            }

            &:hover {
                color: #FDB500;
            }
        }

        .topact {
            color: #FDB500;
        }
    }

    .topimg,.brand-select {
        display: none;
    }

    ::v-deep .el-main {
        padding: 0 0 15px 0;
    }
}

@media (max-width:1200px) {
    .brand {
        padding: 0 57px;
        background: url('../../public/images/brand/samllbg1.png') center no-repeat;
        background-size: 100% 100%;

        ::v-deep .el-aside {
            display: none;
        }
        ::v-deep .el-header{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: fit-content;
        padding: 0 62px;
        background: #000;
        z-index: 5;
    }

        .topimg {
            padding-top: 23px;
            display: block;

            img {
                width: 112px;
                height:18px;
            }
        }
        .brand-select{
            display: block;
            margin-top: 34px;
            padding-bottom: 18px;
            ::v-deep .el-select{
                width: 159px;
                .el-input__wrapper{
                    position: relative;
                    background-color: rgba(118, 118, 118, 0);
                    border: 0.5px solid #B8B8B8;
                    box-shadow: none;
                    &::after{
                        content: '';
                        position: absolute;
                        top: 6px;
                        right: 7px;
                        width: 8px;
                        height: 8px;
                        background: url('../../public/images/brand/downicon.png') no-repeat;
                        background-size: 8px 8px;
                        z-index: 56;
                        transition: .5s;
                    }
                }
                .el-input__inner{
                    position: relative;
                    color: #fff;
                    height: 21px;
                    font-size: 14px;
                  text-transform: capitalize;
                  line-height: 21px;
                }
                .is-focus{
                    &::after{
                        transform: rotate(-180deg);
                        transition: .5s;
                    }
                }
                .el-input__suffix{
                    display: none;
                }
            }
        }

        .brand-top {
            padding-top: 25px;
            font-size: 14px;
            justify-content: space-between;
            line-height: 13px;
        }

        ::v-deep .el-main {
            padding: 0;
        }
    }
}
</style>